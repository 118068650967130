import React, { Component } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToken, addRefToken, addExpToken, addExpRefToken } from './store/actions/ConfigAction';
import './scss/style.scss';
import axios from 'axios';
import moment from 'moment';
import publicUrl from './publicUrl';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      isAuth: false
    };
  }

  componentDidMount() {
    this.checkCondition(this.props.token)
  }

  authToken() {
    axios.post(publicUrl.API_URL + "auth/refresh", {
      refreshToken: this.props.reftoken
    })
    .then(res => {
      localStorage.setItem("token", res.data.data.access.token);
      localStorage.setItem("date", res.data.data.access.expires);
      localStorage.setItem("reftoken", res.data.data.refresh.token);
      localStorage.setItem("expdate", res.data.data.refresh.expires);
      this.props.onAddToken(res.data.data.access.token);
      this.props.onAddRefToken(res.data.data.refresh.token);
      this.props.onAddExpToken(res.data.data.access.expires);
      this.props.onAddExpRefToken(res.data.data.refresh.expires);
      axios.defaults.headers.common['Authorization'] = "Bearer" + " " + res.data.data.access.token;
    })
    .catch(err => {

    })
  }

  checkCondition(token) {
    if(token) {
      this.setState({
        isAuth: true
      });
      if(moment(new Date(this.props.date)).isSameOrBefore(moment(new Date()))) {
        // axios.defaults.headers.common['Authorization'] = "Bearer" + " " + this.props.reftoken;
        this.authToken();
      } else {
        axios.defaults.headers.common['Authorization'] = "Bearer" + " " + this.props.token;
      }
    }
  }


  componentDidUpdate(prevProps) {
    if(prevProps.token != this.props.token) {
      this.setState({
        isAuth: true
      });
      if(moment(new Date(this.props.date)).isSameOrBefore(moment(new Date()))) {
        // axios.defaults.headers.common['Authorization'] = "Bearer" + " " + this.props.reftoken;
        this.authToken();
      } else {
        axios.defaults.headers.common['Authorization'] = "Bearer" + " " + this.props.token;
      }
      return;
    }
  }

  

  render() {
    const {isAuth} = this.state;
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => isAuth == false ? <Login {...props}/> : <Redirect to='/dashboard'/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={
                props =>  isAuth == false ? 
                <Redirect to="/login"/> : 
                <TheLayout {...props}/>} 
              />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
};

const mapStatestoProps = state => {
  return {
    token: state.config.token,
    reftoken: state.config.reftoken,
    date: state.config.date,
    expdate: state.config.expdate,
    id: state.config.id,
    role: state.config.role
  }
};

const dispatchToProps = dispatch => {
  return {
    onAddToken: token => dispatch(addToken(token)),
    onAddRefToken: token => dispatch(addRefToken(token)),
    onAddExpToken: date => dispatch(addExpToken(date)),
    onAddExpRefToken: date => dispatch(addExpRefToken(date))
  }
};

export default connect(mapStatestoProps, dispatchToProps)(App);
