import { SET_DISPLAY, ADD_TOKEN, ADD_REFTOKEN,  ADD_EXP_REFTOKEN, ADD_EXP_TOKEN, ADD_USERID, ADD_ROLE ,SELECT_TOKEN, 
    DESELECT_TOKEN, DESELECT_USERID, DESELECT_ROLE } from "./ActionsType";

export const setDisplay = (disp) => {
    return {
        type: SET_DISPLAY,
        sidebarShow: disp
    };
}

export const addToken = (token) => {
    return {
        type: ADD_TOKEN,
        token: token
    };
}

export const addExpToken = (date) => {
    return {
        type: ADD_EXP_TOKEN,
        date: date
    };
}

export const addRefToken = (reftoken) => {
    return {
        type: ADD_REFTOKEN,
        reftoken: reftoken
    };
}

export const addExpRefToken = (expdate) => {
    return {
        type: ADD_EXP_REFTOKEN,
        expdate: expdate
    };
}

export const addUserId = (id) => {
    return {
        type: ADD_USERID,
        id: id
    };
}

export const addRole = (role) => {
    return {
        type: ADD_ROLE,
        role: role
    };
}

export const selectToken = () => {
    return {
        type: SELECT_TOKEN
    }
}

export const deselectToken = () => {
    return {
        type: DESELECT_TOKEN
    }
}

export const deselectUserId = () => {
    return {
        type: DESELECT_USERID
    }
}

export const deselectRole = () => {
    return {
        type: DESELECT_ROLE
    }
}



