import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import ConfigReducers from "./../src/store/reducers/ConfigReducers";
import thunk from 'redux-thunk';

const initialState = {
  sidebarShow: 'responsive'
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  config: ConfigReducers
  // config: null
});

const ConfigureStore = () => {
  const store = createStore(
    // changeState,
    rootReducer,
    compose(applyMiddleware(thunk))
  );

  return {store}
} 
  
export default ConfigureStore;