import { SET_DISPLAY, ADD_TOKEN, ADD_REFTOKEN, ADD_EXP_TOKEN, ADD_EXP_REFTOKEN, ADD_USERID ,SELECT_TOKEN, 
    DESELECT_TOKEN, DESELECT_USERID, DESELECT_ROLE, ADD_ROLE } from "../actions/ActionsType";

const initialState = {
    token: localStorage.getItem("token"),
    reftoken: localStorage.getItem("reftoken"),
    date: localStorage.getItem("date"),
    expdate: localStorage.getItem("expdate"),
    id: null,
    role: localStorage.getItem("role"),
    sidebarShow: 'responsive'
};

export default (state = initialState, action) => 
{
    switch (action.type) {
        case SET_DISPLAY:
            return {
                ...state,
                sidebarShow: action.sidebarShow
            }
        case ADD_TOKEN:
            return {
                ...state,
                token: action.token
            }
        case ADD_REFTOKEN:
            return {
                ...state,
                reftoken: action.reftoken
            }
        case ADD_EXP_REFTOKEN:
            return {
                ...state,
                expdate: action.expdate
            }
        case ADD_EXP_TOKEN:
            return {
                ...state,
                date: action.date
            }
        case ADD_USERID:
            return {
                ...state,
                id: action.id
            }
        case ADD_ROLE:
            return {
                ...state,
                role: action.role
            }
        case SELECT_TOKEN:
            return { state }
        case DESELECT_TOKEN:
            return {
                ...state,
                token: null,
                reftoken: null
            }
        case DESELECT_USERID:
            return {
                ...state,
                id: null
            }
        case DESELECT_ROLE:
            return {
                ...state,
                role: null
            }
        default:
            return state
    }
}
