export const SET_DISPLAY = 'SET_DISPLAY';
export const ADD_TOKEN = 'ADD_TOKEN';
export const ADD_EXP_TOKEN = 'ADD_EXP_TOKEN';
export const ADD_REFTOKEN = 'ADD_REFTOKEN';
export const ADD_EXP_REFTOKEN = 'ADD_EXP_REFTOKEN';
export const ADD_USERID = 'ADD_USERID';
export const ADD_ROLE = 'ADD_ROLE';
export const SELECT_TOKEN = 'SELECT_TOKEN';
export const DESELECT_TOKEN = 'DESELECT_TOKEN';
export const DESELECT_REFTOKEN = 'DESELECT_REFTOKEN';
export const DESELECT_USERID = 'DESELECT_USERID';
export const DESELECT_ROLE = 'DESELECT_ROLE';

